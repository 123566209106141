<template v-if="fetch_data">
  <div class="shop_menu flex">
    <div class="flex">
      <template v-if="fetch_data.shopping_count">
        <router-link :to="{ name: 'ShoppingCart'}" class="flex">
          <i class="cart"><span class="badge"
                                v-if="fetch_data.shopping_count">{{
              delimited(fetch_data.shopping_count)
            }}</span></i>
          <p>カートを見る</p>
        </router-link>
      </template>
      <template v-else>
        <i class="cart"></i>
        <p>カートを見る</p>
      </template>
    </div>
    <div class="flex">
          <span class="flex"><i class="point"></i>
            <p><span>{{ delimited(fetch_data.total_points) }}</span>ポイント</p>
          </span>
    </div>
    <div class="flex">
      <template v-if="fetch_data.usable_shop_coupons.length">
        <a href="javascript:void(0)" class="flex" v-on:click="show_coupon_modal = true">
          <i class="coupon"><span class="badge" v-if="fetch_data.usable_shop_coupons.length">{{
              delimited(fetch_data.usable_shop_coupons.length)
            }}</span></i>
          <p>クーポンを見る</p>
        </a>
      </template>
      <template v-else>
          <i class="coupon"></i>
          <p>クーポンを見る</p>
      </template>
    </div>
    <div class="flex">
      <a href="https://www.millioneyes.jp/shop_info/" class="flex">
        <i class="guide"></i>
        <p>ご利用ガイド</p>
      </a>
    </div>

    <div class="modal" v-bind:class="{open: show_coupon_modal}" v-if="show_coupon_modal">
      <div class="modal_box large">
        <div class="header">
          <h4>現在所有しているクーポン</h4>
          <div class="close modal_close" v-on:click="show_coupon_modal = false"></div>
        </div>
        <div class="inner draft cart">
          <div class="scroll_wrap">
            <div class="scroll_box coupon workshop">
              <h4 class="bold mb10">現在以下のクーポンが利用可能です。</h4>
              <ul class="coupon_list bdr">
                <li class="flex" v-for="coupon in fetch_data.usable_shop_coupons" :key="`usable_coupon-${coupon.id}`">
                  <div class="thumb"><img v-bind:src="coupon.image_file_url" alt=""></div>
                  <div class="detail">
                    <div class="head flex">
                      <h3>{{ coupon.title }}</h3>
                      <div class="dead_line">有効期限：<span>{{ moment(coupon.close_date, 'YYYY年MM月DD日') }}</span>
                      </div>
                    </div>
                    <p>
                      {{ coupon.description }}
                    </p>
                  </div>
                  <div class="btn_wrap">
                    <router-link :to="{path: coupon.use_url}" class="btn" @click.native="show_coupon_modal=false">
                      商品ページ
                    </router-link>
                  </div>
                </li>
              </ul>
              <div class="btn_wrap">
                <p class="alnC bold mb20">クーポンを利用するには、お会計画面の<br class="sp">「クーポン・ポイントのご利用」ページで<br class="sp">「クーポンを利用する」を押してください。
                </p>
                <a href="javascript:void(0)" v-on:click="show_coupon_modal = false" class="submit light skeleton">買い物を続ける</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ovr"></div>
    </div>

  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    return {
      show_coupon_modal: false
    }
  },
  props: {
    fetch_data: null
  }
}
</script>