<template>
  <div id="container" v-if="fetch_data">

    <section id="shop">
      <div class="wrap">

        <div class="cts_box mb20">
          <h2 class="main_ttl"><span><router-link :to="{ name: 'ShoppingArticles'}">ショップ</router-link></span></h2>
          <ShopMenu :fetch_data="fetch_data"/>
        </div>

        <div class="cts_box">
          <h2 class="min_ttl">ショッピングカート</h2>
          <div class="in_cart">
            <span>{{ delimited(fetch_data.shopping_count) }}</span>点の商品がカートに入っています。
          </div>
          <div class="cart_box">
            <div class="cart_item flex" v-for="article in fetch_data.shopping.articles" v-bind:key="article.article.id">
              <div class="image"><img v-bind:src="article.article.image_files[0]" alt=""></div>
              <div class="cart_cts">
                <h3 class="item_ttl">{{ article.article.title }}</h3>
                <p class="price">¥ {{ delimited(article.price) }}</p>
                <template v-if="false">
                  <div class="tagbox" v-if="article.article.point_usable"><span class="tag">ポイント払い可能</span></div>
                  <div class="tagbox" v-else><span class="tag">ポイント払い不可</span></div>
                </template>
                <div class="point">ポイント：<span>3%</span>（ポイント利用分には付与されません）</div>
                <div class="item_count flex">
                  <p>数量</p>
                  <select v-model="article.count" @change="updateCart(article)">
                    <option v-for="(data, index) in new Array(Math.max(10, article.count))" v-bind:value="index + 1" :key="'c-' + index">{{index + 1}}</option>
                  </select>
                  <div class="delate" @click="article.count = 0; updateCart(article)">× この商品を削除する</div>
                </div>
              </div>
            </div>
          </div>

          <div class="cart_all flex">
            <div class="count_all">カートの合計（{{ delimited(fetch_data.shopping_count) }}点の商品）（税込）：</div>
            <div class="price_all">¥ {{ delimited(fetch_data.total_price) }}</div>
          </div>

          <div class="btnBox mb40">
            <router-link :to="{ name: 'ShoppingRegister'}" class="submit light arrow">
              お届け先の入力
            </router-link>
          </div>

          <div class="btnBox">
            <p class="alnC mb10">お買い物を続ける場合は<br class="sp">こちらのボタンを押してください。</p>
            <a @click="$router.go(-1)" class="submit light skeleton arrow back">前のページにもどる</a>
          </div>

        </div>

      </div>
    </section>
  </div>
</template>

<script>

import Libraries from '@/components/Libraries'
import ShopMenu from '@/views/shop/_shop_menu'

export default {
  mixins: [Libraries],
  components: {
    ShopMenu
  },
  data() {
    return {
      fetch_data: null
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    pointPercent(article) {
      return article.add_points * 100 / article.price;
    },
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}shop/shopping.json`)
          .then(response => {
            this.fetch_data = response.data
            if (this.fetch_data.shopping_count === 0) {
              this.$router.push({name: 'ShoppingArticles'})
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },
    updateCart(article) {
      this.axios
          .post(`${this.env.api_base_url}shop/update_cart.json`, {
            id: article.id,
            count:article.count
          })
          .then(response => {
            this.fetch_data = response.data
            if (this.fetch_data.shopping_count === 0) {
              this.$router.push({name: 'ShoppingArticles'})
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })
    }
  }
}
</script>
